import React from 'react';
import { View } from 'styleguide/View';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { TextSMRegular } from 'styleguide/Texts';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { observer } from 'mobx-react-lite';
import { ProfileImage } from 'components/ProfileImage';
import { CoachAccount } from 'hevy-shared';
import { MenuProps } from '@szhsin/react-menu';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { colors } from 'styleguide/colors';
import { CardNoPadding } from 'styleguide/Card';
import { HevyMenu } from 'components/HevyMenu/HevyMenu';
import { zIndexes } from 'styleguide/zIndex';
import { SearchInput, StyledCheckbox } from 'styleguide/Inputs';
import { coachMatchesSearch } from 'utils/pureUtils';
import { DividerLine } from 'styleguide/DividerLine';
import { TextButton } from 'styleguide/Buttons';

const SelectedCoachContainer = styled(CardNoPadding)<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${Spacing.md}px;
  padding: ${Spacing.sm}px ${Spacing.md}px;
  margin-bottom: 0;
  cursor: pointer;
  border-radius: ${CornerRadius.sm}px;
  background-color: ${props => (props.isActive ? colors.primary50 : colors.white)};
  height: 16px;
`;

const SearchInputContainer = styled(FlexRow)`
  padding: ${Spacing.md}px;
`;

const OptionsContainer = styled(View)`
  overflow-y: auto;
  max-height: 300px;
`;

const OptionContainer = styled(FlexRow)`
  gap: ${Spacing.sm}px;
  padding: ${Spacing.sm}px ${Spacing.md}px;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background-color: ${colors.neutral100};
  }
`;

const LowerContainer = styled(View)`
  align-items: flex-end;
  padding: ${Spacing.md}px;
`;

type CoachFilterProps = Omit<MenuProps, 'menuButton'> & {
  coachOptions: CoachAccount[];
  selectedCoachIds: string[];
  viewerCoachId: string;
  onSelectCoach: (coachId: string) => void;
  onSelectOneCoach: (coachId: string) => void;
  onSelectAllCoaches: () => void;
  onClearSelectedCoaches: () => void;
};

export const CoachFilter = observer(
  ({
    selectedCoachIds,
    coachOptions,
    onSelectCoach,
    onSelectOneCoach,
    onSelectAllCoaches,
    onClearSelectedCoaches: onClearSelectedCoachs,
    ...props
  }: CoachFilterProps) => {
    const selectorRef = React.useRef(null);
    const [searchText, setSearchText] = React.useState('');
    const [hoveringCoachId, setHoveringCoachId] = React.useState<string | null>(null);

    const selectedCoaches = coachOptions.filter(coach => selectedCoachIds.includes(coach.id));
    const filteredCoaches = coachOptions.filter(coach => coachMatchesSearch(coach, searchText));
    const isAllCoachesSelected = selectedCoachIds.length === coachOptions.length;

    return (
      <HevyMenu
        {...props}
        arrow={false}
        align="start"
        menuButton={
          <SelectedCoachContainer
            isActive={!isAllCoachesSelected}
            ref={selectorRef}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {!isAllCoachesSelected && selectedCoaches.length > 0 ? (
              <FlexRow style={{ gap: Spacing.sm }}>
                <ProfileImage source={selectedCoaches[0].profile_pic} diameter={20} />
                <TextSMRegular style={{ color: colors.primary500, userSelect: 'none' }}>
                  Coach: {selectedCoaches[0].full_name || selectedCoaches[0].username}
                  {selectedCoaches.length > 1 ? ` +${selectedCoaches.length - 1}` : ''}
                </TextSMRegular>
              </FlexRow>
            ) : (
              <>
                <TextSMRegular style={{ userSelect: 'none' }} type="secondary">
                  {isAllCoachesSelected ? 'All coaches' : 'Select coach'}
                </TextSMRegular>

                <CustomizableIcon
                  type="chevron-down"
                  tint={colors.neutral900}
                  style={{ flexShrink: 0 }}
                />
              </>
            )}
          </SelectedCoachContainer>
        }
        onItemClick={e => {
          e.syntheticEvent.stopPropagation();
          e.syntheticEvent.preventDefault();
        }}
        menuStyle={{
          padding: 0,
          width: 300,
          border: `1px solid ${colors.neutral150}`,
          backgroundColor: colors.white,
          borderRadius: CornerRadius.sm,
          boxShadow: '0px 4px 16px -2px rgba(16, 24, 40, 0.1)',
          zIndex: zIndexes.modalOverlay,
        }}
      >
        <SearchInputContainer>
          <SearchInput
            placeholder="Search coaches"
            onChange={setSearchText}
            value={searchText}
            style={{ backgroundColor: colors.neutral100, width: '100%' }}
          />
        </SearchInputContainer>
        <DividerLine />
        <FlexRow
          style={{ gap: Spacing.sm, padding: Spacing.md, cursor: 'pointer' }}
          onClick={e => {
            isAllCoachesSelected ? onClearSelectedCoachs() : onSelectAllCoaches();
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <StyledCheckbox checked={isAllCoachesSelected} />
          <TextSMRegular type="secondary">All coaches</TextSMRegular>
        </FlexRow>
        <DividerLine />
        <OptionsContainer>
          {filteredCoaches.map(coach => {
            return (
              <OptionContainer
                key={coach.id}
                onClick={() => onSelectCoach(coach.id)}
                onMouseEnter={() => setHoveringCoachId(coach.id)}
                onMouseLeave={() => setHoveringCoachId(null)}
              >
                <FlexRow style={{ gap: Spacing.sm }}>
                  <StyledCheckbox
                    checked={selectedCoachIds.includes(coach.id)}
                    onChange={() => onSelectCoach(coach.id)}
                  />
                  <ProfileImage source={coach.profile_pic} diameter={20} />
                  <TextSMRegular type="secondary">
                    {coach.full_name || coach.username}
                  </TextSMRegular>
                </FlexRow>

                {hoveringCoachId === coach.id && (
                  <TextButton
                    title="Only"
                    onClick={e => {
                      onSelectOneCoach(coach.id);
                      e.stopPropagation();
                    }}
                  />
                )}
              </OptionContainer>
            );
          })}
        </OptionsContainer>
        <DividerLine />
        <LowerContainer>
          <TextButton title="Clear" onClick={onSelectAllCoaches} />
        </LowerContainer>
      </HevyMenu>
    );
  },
);
