'use client';
import React from 'react';
import Head from 'next/head';
import { BootstrapLoadingWrapper } from 'components/BootstrapLoadingWrapper';
import { metaDescription, metaImage, metaTitle } from 'utils/metaTags';
import { DashboardScreen } from 'screens/Dashboard/DashboardScreen';
import { localStorageStores } from 'state/localStorageStores';
import { WelcomeScreen } from 'screens/Authentication/Welcome/WelcomeScreen';
import { getAuthenticatedPropWithoutRedirect } from 'utils/pageUtils';
import { GetServerSideProps } from 'next';

export const getServerSideProps: GetServerSideProps = getAuthenticatedPropWithoutRedirect;
function RootIndexPage() {
  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:site_name" content="Hevy Coach" />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@hevyapp" />
        <meta name="twitter:site" content="@hevyapp" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
      </Head>
      <BootstrapLoadingWrapper>
        {localStorageStores.auth.isAuthenticated ? <DashboardScreen /> : <WelcomeScreen />}
      </BootstrapLoadingWrapper>
    </>
  );
}

export default RootIndexPage;
