import { AddClientButton } from 'components/AddClientButton';
import router from 'next/router';
import React from 'react';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { FlexRow } from 'styleguide/Row';
import { HeaderText, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { ClientSearch } from './ClientSearch/ClientSearch';
import { MyClientsFilter } from 'components/Filters/MyClientsFilter';
import { CoachFilter } from 'components/Filters/CoachFilter';
import { CoachAccount } from 'hevy-shared';

const Container = styled(View)`
  gap: ${Spacing.lg}px;
`;

const UpperContainer = styled(FlexRow)`
  gap: ${Spacing.md}px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FilterContainer = styled(FlexRow)`
  gap: ${Spacing.md}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    flex-wrap: wrap;
  }
`;

const ClientSearchRow = styled(FlexRow)`
  gap: ${Spacing.md}px;
  flex: 1;
  justify-content: flex-end;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    justify-content: space-between;
  }
  @media (max-width: 360px) {
    flex-wrap: wrap;
  }
`;

export const DashboardHeader = ({
  coachName,
  coachImageUrl,
  showTeamInfo,
  isMyClientsFilterActive,
  onMyClientsFilterClick,
  coachOptions,
  selectedCoachIds,
  viewerCoachId,
  onSelectCoach,
  onSelectAllCoaches,
  onClearSelectedCoaches,
  onSelectOneCoach,
}: {
  coachName: string;
  coachImageUrl?: string;
  showTeamInfo: boolean;
  isMyClientsFilterActive: boolean;
  onMyClientsFilterClick: () => void;
  coachOptions: CoachAccount[];
  selectedCoachIds: string[];
  viewerCoachId: string;
  onSelectCoach: (coachId: string) => void;
  onSelectAllCoaches: () => void;
  onClearSelectedCoaches: () => void;
  onSelectOneCoach: (coachId: string) => void;
}) => {
  const windowWidth = useWindowSize().width;

  return (
    <Container>
      <UpperContainer>
        <View>
          <HeaderText>Hello, {coachName} 👋</HeaderText>
          <TextSMRegular>
            {showTeamInfo
              ? "Get an overview of the team's clients' progress."
              : "Get an overview of your clients' progress."}
          </TextSMRegular>
        </View>
        <ClientSearchRow>
          <ClientSearch
            style={{ minWidth: 180, maxWidth: windowWidth > 360 ? 280 : 'none', width: '100%' }}
            inputStyle={{ height: 38 }}
            onSelectClient={client => router.push(`/clients/${client.id}`)}
          />
          <AddClientButton
            source={'dashboard'}
            textStyle={{ whiteSpace: 'nowrap' }}
            style={{ width: windowWidth > 360 ? 'auto' : '100%' }}
          ></AddClientButton>
        </ClientSearchRow>
      </UpperContainer>
      {showTeamInfo && (
        <FilterContainer>
          <MyClientsFilter
            isActive={isMyClientsFilterActive}
            onClick={onMyClientsFilterClick}
            profileImageUrl={coachImageUrl}
          />
          <CoachFilter
            coachOptions={coachOptions}
            selectedCoachIds={selectedCoachIds}
            onSelectCoach={onSelectCoach}
            onSelectOneCoach={onSelectOneCoach}
            onSelectAllCoaches={onSelectAllCoaches}
            onClearSelectedCoaches={onClearSelectedCoaches}
            viewerCoachId={viewerCoachId}
          />
        </FilterContainer>
      )}
    </Container>
  );
};
