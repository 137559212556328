import { ProfileImage } from 'components/ProfileImage';
import React from 'react';
import styled from 'styled-components';
import { CardNoPadding } from 'styleguide/Card';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { FlexRow } from 'styleguide/Row';
import { TextSMRegular } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';

const Container = styled(CardNoPadding)<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${Spacing.sm}px;
  padding: ${Spacing.sm}px ${Spacing.md}px;
  margin-bottom: 0px;
  border-radius: ${CornerRadius.sm}px;
  background-color: ${props => (props.isActive ? colors.primary50 : colors.white)};
  height: 16px;
  cursor: pointer;
`;

export const MyClientsFilter = ({
  isActive,
  onClick,
  profileImageUrl,
}: {
  isActive: boolean;
  onClick: () => void;
  profileImageUrl?: string;
}) => {
  return (
    <Container onClick={onClick} isActive={isActive}>
      <FlexRow style={{ gap: Spacing.xs }}>
        <ProfileImage source={profileImageUrl} diameter={20} />
        <TextSMRegular
          style={{ color: isActive ? colors.primary500 : colors.neutral900, userSelect: 'none' }}
        >
          My Clients
        </TextSMRegular>
      </FlexRow>

      {isActive && <CustomizableIcon type="cross-small" tint={colors.primary500} />}
    </Container>
  );
};
