import React, { useEffect } from 'react';
import { TextMD } from 'styleguide/Texts';
import { MarginView } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { DashboardStatsCard } from './components/DashboardStatsCard';
import { observer } from 'mobx-react-lite';
import { LatestActivityCard } from 'components/LatestActivity/LatestActivityList';
import { WeeklyClientDataGraph } from './components/BarChart';
import { CardNoPadding } from 'styleguide/Card';
import { DividerLine } from 'styleguide/DividerLine';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { TutorialCard } from './components/TutorialCard/TutorialCard';
import { ScreenContainer } from 'styleguide/ScreenContainer';
import { DashboardHeader } from './components/DashboardHeader';
import { dashboard } from 'utils/globals/dashboard';

const Container = styled(ScreenContainer)`
  flex: 1;
  gap: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    gap: ${Spacing.md}px;
  }
`;

const UpperSection = styled(FlexRow)`
  align-items: flex-start;
  gap: ${Spacing.lg}px;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    gap: ${Spacing.md}px;
  }
`;

const LowerSection = styled(FlexRow)`
  gap: ${Spacing.lg}px;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  @media (max-width: 1024px) {
    gap: ${Spacing.md}px;
  }
`;

const GraphCard = styled(CardNoPadding)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 0;
  height: 520px;
  min-width: min(100%, 400px);
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    height: 350px;
  }
`;

export const DashboardScreen = observer(() => {
  useEffect(() => {
    dashboard.refresh();
  }, []);

  return (
    <MarginView>
      <Container>
        {dashboard.isTutorialActive && (
          <TutorialCard
            hasInvitedAClient={dashboard.hasInvitedAClient}
            hasCreatedWorkoutProgram={dashboard.hasCreatedAWorkoutProgram}
            hasAssignedAProgram={dashboard.hasAssignedAWorkoutProgramToArealClient}
          />
        )}

        <DashboardHeader
          coachName={dashboard.viewerCoach.personalCoachName}
          showTeamInfo={dashboard.showTeamInfo}
          coachImageUrl={dashboard.viewerCoach.profile_pic}
          isMyClientsFilterActive={dashboard.isMyClientsFilterActive}
          onMyClientsFilterClick={dashboard.onMyClientsFilterClick}
          coachOptions={dashboard.teamMembers}
          selectedCoachIds={dashboard.filteredCoachIds}
          onSelectCoach={dashboard.onSelectCoachFilter}
          onSelectAllCoaches={dashboard.onSelectAllCoachesFilter}
          onClearSelectedCoaches={dashboard.onClearSelectedCoachs}
          onSelectOneCoach={dashboard.onSelectOneCoachFilter}
          viewerCoachId={dashboard.viewerCoach.id}
        />

        <UpperSection>
          <DashboardStatsCard
            title="Total Clients"
            stat={dashboard.filteredClientsCount}
            clients={dashboard.filteredClients}
            textStyle={{ marginRight: 105 }}
          />
          <DashboardStatsCard
            title="Active clients last 7 days"
            stat={dashboard.activeClientsCountLastSevenDays}
            clients={dashboard.activeClientsLastSevenDays}
            textStyle={{ marginRight: 11.5 }}
          />
          <DashboardStatsCard
            title="Inactive clients last 7 days"
            stat={dashboard.nonActiveClientsCountLastSevenDays}
            clients={dashboard.nonActiveClientsSevenDays}
          />
        </UpperSection>
        <LowerSection>
          <GraphCard>
            <FlexRow style={{ padding: `${Spacing.md}px ${Spacing.lg}px` }}>
              <TextMD>Weekly Active Clients</TextMD>
            </FlexRow>
            <DividerLine />
            <WeeklyClientDataGraph
              isLoading={dashboard.isLoadingWeeklyActiveClients}
              data={dashboard.activeClientsData}
            />
          </GraphCard>
          <LatestActivityCard
            latestActivities={dashboard.filteredLatestActivities}
            unit={dashboard.coachWeightUnit}
            isLoading={dashboard.isLoadingActivities}
            emptyMessage="Your clients do not have any recent activities"
            fetchMore={async () => {
              await dashboard.fetchMoreClientActivities();
            }}
            style={{ minWidth: `min(100%, 400px)` }}
            listHeight={463}
            showProfilePic={true}
            showAddClientButton={true}
            enableClickingClient={true}
          ></LatestActivityCard>
        </LowerSection>
      </Container>
    </MarginView>
  );
});
