import React from 'react';
import styled from 'styled-components';
import { TextLG } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { sendEvent } from 'utils/analyticsEvents';
import ComputerSvg from './assets/computer.png';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import Image from 'next/image';

const Container = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.neutral100};
  border-radius: ${CornerRadius.md}px;
  padding: ${Spacing.md}px;
  width: 288px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    width: 100%;
  }
  text-decoration: none;
  cursor: pointer;
`;

export const GettingStartedGuide = () => {
  return (
    <Container
      onClick={() => {
        sendEvent('tutorialCard_gettingStartedGuide_click');
      }}
      href="https://help.hevycoach.com/en/articles/8480529-getting-started-guide"
      target="_blank"
      rel="noreferrer"
    >
      <Image alt="getting-started" width="227" height="121" src={ComputerSvg} />
      <TextLG style={{ marginTop: Spacing.sm + Spacing.xs, textAlign: 'center' }}>
        Read Getting Started Guide
      </TextLG>
    </Container>
  );
};
