import React from 'react';
import styled from 'styled-components';
import { DividerLine } from 'styleguide/DividerLine';
import { View } from 'styleguide/View';
import { TextXSMedium } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { colors } from 'styleguide/colors';
import { TABLET_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { FlexRow } from 'styleguide/Row';

const Container = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 51px;
  width: 100%;
  @media (max-width: ${TABLET_WIDTH_BREAKPOINT}px) {
    display: none;
  }
  background-color: ${colors.white};
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${Spacing.md}px;
  margin-bottom: ${Spacing.md}px;
`;

const FooterLink = styled.a`
  text-decoration: none;
`;

const TextXSM400 = styled(TextXSMedium)`
  color: ${colors.neutral400};
`;

export const Footer = () => {
  return (
    <Container>
      <DividerLine />
      <BottomContainer>
        <FlexRow
          style={{ width: '100%', gap: Spacing.lg, textAlign: 'left', marginLeft: Spacing.lg }}
        >
          <FooterLink target="_blank" href="https://hevycoach.com/terms-and-conditions/">
            <TextXSM400>Terms &amp; Conditions</TextXSM400>
          </FooterLink>
          <FooterLink target="_blank" href="https://hevycoach.com/privacy-policy/">
            <TextXSM400>Privacy Policy</TextXSM400>
          </FooterLink>
          <FooterLink target="_blank" href="https://hevycoach.com/about-us/">
            <TextXSM400>Contact Us</TextXSM400>
          </FooterLink>
        </FlexRow>
        <View style={{ width: '100%', textAlign: 'right', marginRight: Spacing.lg }}>
          <TextXSM400>© 2023 Hevy Studios S.L. All rights reserved.</TextXSM400>
        </View>
      </BottomContainer>
    </Container>
  );
};
