import { modal } from 'components/Modals/ModalManager';
import { OverlappingCircleImages } from 'components/OverlappingImages';
import React from 'react';
import styled from 'styled-components';
import { CardNoPadding } from 'styleguide/Card';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { DividerLine } from 'styleguide/DividerLine';
import { FlexRow } from 'styleguide/Row';
import { DisplaySM, TextMD } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { Client } from 'types/client';

const Container = styled(CardNoPadding)`
  flex: 1;
  margin-bottom: 0px;
  min-width: 200px;
`;

const UpperContainer = styled(FlexRow)`
  justify-content: space-between;
  padding: ${Spacing.md}px ${Spacing.lg}px;
  gap: ${Spacing.sm}px;
`;

const LowerContainer = styled(FlexRow)`
  justify-content: space-between;
  padding: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.md}px;
  }
  gap: ${Spacing.sm}px;
`;

interface DashboardStatsCardProps {
  title: string;
  stat: number;
  clients: Client[];
  textStyle?: React.CSSProperties;
}

export const DashboardStatsCard = ({
  title,
  stat,
  clients,
  textStyle,
}: DashboardStatsCardProps) => {
  const profilePics = clients.map(c => c.profilePic);
  const clientCount = clients.length;

  return (
    <Container
      style={{ cursor: clientCount > 0 ? 'pointer' : 'default' }}
      onClick={() => {
        if (clientCount > 0) modal.openClientListModal({ title, clients });
      }}
    >
      <UpperContainer>
        <TextMD style={textStyle}>{title}</TextMD>
        <CustomizableIcon style={{ flexShrink: 0 }} type="chevron-right" tint={colors.neutral900} />
      </UpperContainer>
      <DividerLine />
      <LowerContainer>
        <DisplaySM>{stat}</DisplaySM>
        <OverlappingCircleImages imageUrls={profilePics} maxToShow={5} />
      </LowerContainer>
    </Container>
  );
};
