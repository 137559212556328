import React, { memo, useState } from 'react';
import { BarChart, Cell, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { TextMDRegular, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import dayjs from 'dayjs';
import { ResponsiveGraphContainer } from 'components/ResponsiveGraphContainer';
import { WeeklyClientData } from '../types';
import { useWindowSize } from 'utils/useSize';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { AddClientButton } from 'components/AddClientButton';
import { EmptyBarGraph } from 'components/Graphs/EmptyGraph';
import { CustomTooltipContainer, defaultTooltipProps } from 'components/Graphs/Tooltip';
import { CustomizedAxisLabel, defaultXAxisProps, defaultYAxisProps } from 'components/Graphs/Axis';
import { calculateFocusedBar, calculateTooltipPosition } from 'components/Graphs/utils';
import { CustomBar } from 'components/Graphs/Bar';
import { CustomCartesianGrid } from 'components/Graphs/Grid';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';

const CHART_TOOLTIP_WIDTH = 200;
const CHART_TOOLTIP_HEIGHT = 100;
const X_AXIS_HEIGHT = 45;

const Container = styled(View)`
  flex: 1;
`;

const TooltipContent = ({ payload, label: startOfWeekDate }: { payload: any; label: string }) => {
  return (
    <View style={{ width: CHART_TOOLTIP_WIDTH }}>
      <View style={{ padding: Spacing.md, paddingBottom: Spacing.xs }}>
        <TextXSRegular>{`${dayjs(startOfWeekDate).format('MMM D')} - ${dayjs(startOfWeekDate)
          .add(1, 'week')
          .subtract(1, 'day') // Subtract a day so the end date is the last included day
          .format('MMM D')}`}</TextXSRegular>
        <TextMDRegular>
          {`${(payload.value as number).toLocaleString()} out of ${
            payload.totalClientCount
          } clients`}
        </TextMDRegular>
      </View>
    </View>
  );
};

interface WeeklyStatisticsGraphProps {
  data: WeeklyClientData;
  isLoading?: boolean;
}

export const WeeklyClientDataGraph = memo(({ data, isLoading }: WeeklyStatisticsGraphProps) => {
  const [valuePositionData, setposData] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  // We keep track of the currently focused bar so we can change the color of the bar
  const [focusedBar, setFocusedBar] = useState<undefined | number>(undefined);

  // We keep track of whether the mouse is in the container so we can
  // show the tooltip till the mouse is completely outside the chart area
  const [isMouseInsideChartContainer, setIsMouseInsideChartContainer] = useState(false);

  let barChartHeight = 420;
  let gridHeight = 394;
  const windowWidth = useWindowSize().width;
  if (windowWidth < PHONE_WIDTH_BREAKPOINT) {
    barChartHeight = 200;
    gridHeight = 224;
  }

  const maxDomainValue = Math.max(...data.map(week => week.totalClientCount));
  const minDomainValue = 0;

  return (
    <Container
      onMouseLeave={() => {
        setIsMouseInsideChartContainer(false);
      }}
      onMouseEnter={() => {
        setIsMouseInsideChartContainer(true);
      }}
    >
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <MediumHevyActivityIndicator />
        </View>
      ) : !data.some(week => week.value > 0) ? (
        <EmptyBarGraph
          title="No active clients"
          subtitle="There have been no active clients in the last 12 weeks"
          barStyle={{ height: barChartHeight }}
          button={<AddClientButton source="dashboard-empty-graph" buttonType="secondary" />}
          xAxisHeight={X_AXIS_HEIGHT}
        ></EmptyBarGraph>
      ) : (
        <ResponsiveGraphContainer>
          <BarChart
            data={data}
            style={{ height: barChartHeight }}
            // Helps ensure selected dots don't clip
            margin={{ top: Spacing.lg, right: Spacing.lg }}
            barSize={20}
            onMouseMove={data => {
              calculateTooltipPosition({
                data,
                dataMinValue: minDomainValue,
                dataMaxValue: maxDomainValue,
                chartHeight: barChartHeight,
                gridHeight: gridHeight,
                offset: 80,
                tooltipWidth: CHART_TOOLTIP_WIDTH,
                setposData,
              });
              calculateFocusedBar({ data, setFocusedBar });
            }}
          >
            <CustomCartesianGrid />
            <XAxis
              {...defaultXAxisProps}
              height={X_AXIS_HEIGHT}
              dataKey="startOfWeekDate"
              tickFormatter={value => dayjs(value).format('MMM D')}
              tick={<CustomizedAxisLabel formatter={value => dayjs(value).format('MMM D')} />}
            />
            <YAxis {...defaultYAxisProps} domain={[minDomainValue, maxDomainValue]} interval={1} />
            <Tooltip
              {...defaultTooltipProps}
              position={{ x: valuePositionData?.x ?? 0, y: valuePositionData?.y ?? 0 }}
              content={
                <CustomTooltipContainer
                  isMouseInsideContainer={isMouseInsideChartContainer}
                  width={CHART_TOOLTIP_HEIGHT}
                  downTriangle={true}
                >
                  <TooltipContent payload={undefined} label={''} />
                </CustomTooltipContainer>
              }
            />
            <CustomBar>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={focusedBar === index ? colors.primary600 : colors.primary500}
                />
              ))}
            </CustomBar>
          </BarChart>
        </ResponsiveGraphContainer>
      )}
    </Container>
  );
});
