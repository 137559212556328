import React, { ReactNode } from 'react';
import { Card } from 'styleguide/Card';
import { TextLG, TextMD, TextMDRegular, TextSMMedium } from 'styleguide/Texts';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import styled from 'styled-components';
import NavChevron from '../assets/chevron-primary.svg';
import { onInviteClientClick } from 'utils/inviteClient';
import { FlexRow } from 'styleguide/Row';
import { GettingStartedGuide } from './GettingStartedGuide';
import { CircleView } from 'styleguide/CircleView';
import { sendEvent } from 'utils/analyticsEvents';

const ChecklistCircle = ({ isComplete, number }: { isComplete: boolean; number: number }) => {
  return (
    <CircleView
      diameter={Spacing.md * 2}
      style={{
        backgroundColor: isComplete ? colors.neutral900 : colors.neutral100,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: Spacing.md,
      }}
    >
      {isComplete ? (
        <CustomizableIcon type="check" tint={colors.white} />
      ) : (
        <TextSMMedium style={{ color: colors.neutral400 }}>{number}</TextSMMedium>
      )}
    </CircleView>
  );
};

const ChecklistItem = ({
  isComplete,
  children,
  number,
}: {
  isComplete: boolean;
  children: ReactNode;
  number: number;
}) => {
  return (
    <FlexRow style={{ marginBottom: Spacing.md }}>
      <ChecklistCircle isComplete={isComplete} number={number} />
      {children}
    </FlexRow>
  );
};

const TitleText = styled(TextLG)`
  margin-bottom: ${Spacing.md}px;
  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const TutorialContent = styled(FlexRow)`
  gap: ${Spacing.sm}px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const TutorialCard = ({
  hasCreatedWorkoutProgram,
  hasInvitedAClient,
  hasAssignedAProgram,
}: {
  hasCreatedWorkoutProgram: boolean;
  hasInvitedAClient: boolean;
  hasAssignedAProgram: boolean;
}) => {
  return (
    <Card style={{ marginBottom: 0 }}>
      <TutorialContent>
        <View>
          <TitleText>Let's get started</TitleText>

          <ChecklistItem isComplete={hasCreatedWorkoutProgram} number={1}>
            {hasCreatedWorkoutProgram ? (
              <TextMDRegular
                style={{
                  textDecoration: 'line-through',
                  color: colors.neutral400,
                  flex: 1,
                }}
              >
                Create a program
              </TextMDRegular>
            ) : (
              <a
                href={'/programs'}
                onClick={() => sendEvent('onboardingTutorial_createProgram_click')}
                style={{ textDecoration: 'none', display: 'inline' }}
              >
                <TextMD style={{ display: 'inline', color: colors.primary500 }}>
                  Create a program
                </TextMD>
                <NavChevron style={{ display: 'inline', marginLeft: Spacing.sm }} />
              </a>
            )}
          </ChecklistItem>
          <ChecklistItem isComplete={hasInvitedAClient} number={2}>
            {hasInvitedAClient ? (
              <TextMDRegular
                style={{ textDecoration: 'line-through', color: colors.neutral400, flex: 1 }}
              >
                Invite a client
              </TextMDRegular>
            ) : (
              <div
                style={{
                  color: colors.neutral900,
                  flex: 1,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onInviteClientClick('tutorial-steps');
                  sendEvent('onboardingTutorial_inviteClient_click');
                }}
              >
                <TextMD style={{ display: 'inline', color: colors.primary500 }}>
                  Invite a client
                </TextMD>
                <NavChevron style={{ display: 'inline', marginLeft: Spacing.sm }} />
              </div>
            )}
          </ChecklistItem>
          <ChecklistItem isComplete={hasAssignedAProgram} number={3}>
            {hasAssignedAProgram ? (
              <TextMDRegular
                style={{ textDecoration: 'line-through', color: colors.neutral400, flex: 1 }}
              >
                Assign a program
              </TextMDRegular>
            ) : (
              <a
                href={'/programs'}
                style={{ textDecoration: 'none', display: 'inline' }}
                onClick={() => sendEvent('onboardingTutorial_assignProgram_click')}
              >
                <TextMD style={{ display: 'inline', color: colors.primary500 }}>
                  Assign a program
                </TextMD>
                <NavChevron style={{ display: 'inline', marginLeft: Spacing.sm }} />
              </a>
            )}
          </ChecklistItem>
        </View>
        <GettingStartedGuide />
      </TutorialContent>
    </Card>
  );
};
