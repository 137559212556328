import React from 'react';
import { AuthenticationScreenWrapper } from '../Components/AuthenticationLayout';
import { WelcomeCard } from './components/WelcomeCard';
import { sendEvent } from 'utils/analyticsEvents';
import router from 'next/router';

export const WelcomeScreen = () => {
  return (
    <AuthenticationScreenWrapper>
      <WelcomeCard
        onClickLogin={() => {
          sendEvent('welcomeScreen_login_click');
          const query = router.query;
          router.push({ pathname: '/login', query });
        }}
        onClickCreateNewAccount={() => {
          sendEvent('welcomeScreen_createNewAccount_click');
          const query = router.query;
          router.push({ pathname: '/signup', query });
        }}
        onClickContinueWithHevyAppAccount={() => {
          sendEvent('welcomeScreen_continueWithHevyAppAccount_click');
          const query = router.query;
          router.push({ pathname: '/signup-with-hevy', query });
        }}
      />
    </AuthenticationScreenWrapper>
  );
};
