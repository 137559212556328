import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import { Client } from 'types/client';
import { clientMatchesSearch } from 'utils/pureUtils';

export class ClientSearchViewModel {
  searchString = '';
  results: Client[] = [];
  onSelectClient: (client: Client) => void;

  constructor(onSelectClient: (client: Client) => void) {
    makeAutoObservable(this);
    this.onSelectClient = onSelectClient;
  }

  get showMenu() {
    return this.searchString.length > 1;
  }

  onSearchStringChanged = (newValue: string) => {
    this.searchString = newValue;
    if (this.showMenu) {
      this.updateResults();
    }
  };

  updateResults = () => {
    const clients = memoryStores.clients.allClients
      .filter(client => {
        return client.id !== localStorageStores.account.id;
      })
      .filter(client => {
        return clientMatchesSearch(client, this.searchString);
      });

    let results: Client[] = [];

    results = results.concat(clients);

    this.results = results;
  };
}
