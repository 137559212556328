import styled from 'styled-components';
import { TABLET_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { colors } from 'styleguide/colors';

export const HeaderNavAndFooterContentContainer = styled.div`
  flex-direction: column;
  display: flex;

  // Space for header and footer
  max-height: calc(100svh - 115px);
  height: calc(100svh - 115px);
  @media (max-width: ${TABLET_WIDTH_BREAKPOINT}px) {
    // No footer on mobile
    max-height: calc(100svh - 62px);
    height: calc(100svh - 62px);
  }
  overflow-y: auto;
`;

export const WhiteScreenContainer = styled.div`
  background-color: ${colors.white};
`;
