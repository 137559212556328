import Link from 'next/link';
import React from 'react';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { Card } from 'styleguide/Card';
import { DisplayXS, TextSMRegular, TextLG } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { useDeviceSize } from 'utils/useSize';

interface WelcomeCardProps {
  onClickLogin?: () => void;
  onClickCreateNewAccount: () => void;
  onClickContinueWithHevyAppAccount: () => void;
}

export const WelcomeCard = ({
  onClickLogin,
  onClickCreateNewAccount,
  onClickContinueWithHevyAppAccount,
}: WelcomeCardProps) => {
  const deviceSize = useDeviceSize();
  const headerTextAlign = deviceSize === 'desktop' ? 'center' : 'left';

  return (
    <View style={{ width: '100%', maxWidth: 430 }}>
      <DisplayXS style={{ textAlign: headerTextAlign }}>Welcome to Hevy Coach</DisplayXS>
      <TextSMRegular
        style={{
          textAlign: headerTextAlign,
          marginBottom: deviceSize === 'desktop' ? Spacing.lg * 2 : Spacing.lg,
          marginTop: Spacing.sm,
        }}
      >
        Choose how to set up your account
      </TextSMRegular>
      <Card style={{ marginBottom: 0 }}>
        <TextLG>Create new account</TextLG>
        <TextSMRegular style={{ marginTop: Spacing.xs }}>
          Create a new account on Hevy Coach
        </TextSMRegular>
        <PrimaryButton
          style={{ marginTop: Spacing.lg, width: '100%' }}
          title="Create New Account"
          onClick={onClickCreateNewAccount}
        />
      </Card>
      <Card style={{ marginTop: Spacing.lg }}>
        <TextLG>Continue with Hevy App account</TextLG>
        <TextSMRegular style={{ marginTop: Spacing.xs }}>
          Set up Hevy Coach using your existing Hevy App account
        </TextSMRegular>
        <SecondaryButton
          onClick={onClickContinueWithHevyAppAccount}
          style={{ marginTop: Spacing.lg, width: '100%' }}
          title="Continue"
        />
      </Card>
      {!!onClickLogin && (
        <TextSMRegular style={{ color: colors.neutral900, marginTop: Spacing.md }}>
          Already have a Hevy Coach account?{' '}
          <Link href="#" onClick={onClickLogin}>
            Log in
          </Link>
        </TextSMRegular>
      )}
    </View>
  );
};
