import React from 'react';
import styled from 'styled-components';
import { TextXSMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing } from 'styleguide/spacing';
import { ProfileImage } from './ProfileImage';
import { FlexRow } from 'styleguide/Row';

const OverlappingImages = styled(View)`
  border-radius: 32px;
  border: 2px solid ${colors.neutral50};
  margin-left: ${-Spacing.sm}px;
`;

export const OverlappingCircleImages = ({
  imageUrls,
  maxToShow,
}: {
  imageUrls: (string | undefined)[];
  maxToShow: number;
}) => {
  const numberOfImages = imageUrls.length;
  return (
    <FlexRow style={{ gap: Spacing.xs }}>
      <FlexRow
        style={{
          // Offset because the first image has a negative margin
          marginLeft: Spacing.sm,
        }}
      >
        {imageUrls.slice(0, maxToShow).map((pic, index) => (
          <OverlappingImages key={index}>
            <ProfileImage source={pic} diameter={28} />
          </OverlappingImages>
        ))}
      </FlexRow>
      {numberOfImages > maxToShow && (
        <TextXSMedium style={{ color: colors.neutral500 }}>
          +{numberOfImages - maxToShow}
        </TextXSMedium>
      )}
    </FlexRow>
  );
};
