import { Footer } from 'components/Footer';
import {
  WhiteScreenContainer,
  HeaderNavAndFooterContentContainer,
} from 'components/NavBar/components/PageContainers';
import styled, { CSSProperties } from 'styled-components';
import { Spacing } from 'styleguide/spacing';
import React from 'react';
import { TABLET_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { BootstrapLoadingWrapper } from 'components/BootstrapLoadingWrapper';
import { FlexRow } from 'styleguide/Row';

export const AuthenticationContentContainer = styled(FlexRow)`
  margin-top: ${Spacing.lg * 2 + Spacing.md}px;
  margin-bottom: ${Spacing.lg}px;
  margin-left: ${Spacing.md}px;
  margin-right: ${Spacing.md}px;
  @media (max-width: ${TABLET_WIDTH_BREAKPOINT}px) {
    // No footer on mobile
    margin-top: ${Spacing.lg}px;
  }
  justify-content: center;
`;

export const AuthenticationScreenWrapper = ({
  children,
  contentContainerStyle,
}: {
  children: React.ReactNode;
  contentContainerStyle?: CSSProperties;
}) => {
  return (
    // We're using the bootstrap wrapper because it allows
    // us to conditionally render styles based on window width, without
    // having a hydration error (because the server window width is unknown)
    <BootstrapLoadingWrapper>
      <WhiteScreenContainer>
        <HeaderNavAndFooterContentContainer>
          <AuthenticationContentContainer style={contentContainerStyle}>
            {children}
          </AuthenticationContentContainer>
        </HeaderNavAndFooterContentContainer>
        <Footer />
      </WhiteScreenContainer>
    </BootstrapLoadingWrapper>
  );
};
